<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g" v-if="!current._id">
        <div class="card">
            <div class="card-header">
              <h5>
                الكشوف <button class="btn btn-primary btn-sm" v-b-modal.create style="border-radius: 5px">
                  <i class="fa fa-plus"></i> انشاء كشف جديد
                </button>
              </h5>
              <b-modal id="create" title="انشاء كشف جديد" hide-footer size="sm">
                <div class="form-group">
                  <h5 for="">عنوان / اسم الكشف</h5>
                  <input type="text"
                    class="form-control" v-model="create.title" placeholder="اكتب هنا...">
                </div>
                <div class="form-group">
                  <h5 for="">النوع</h5>
                  <select class="form-control" v-model="create.type">
                    <option value="students">للطلاب</option>
                    <option value="teachers">للمعلمين</option>
                  </select>
                </div>
                <div class="col-12 text-center g">
                  <button class="btn btn-primary" @click="createNow()">
                    انشاء الكشف
                  </button>
                </div>
              </b-modal>
            </div>
            <div class="card-body">
                <div class="list-group">
                  <button type="button" v-for="item in list" :key="item._id" @click="current = item; header = current.header; footer = current.footer; gett2()" class="list-group-item list-group-item-action">
                    <small class="fa fa-user text-primary" v-if="item.type == 'teachers'"> للمعلمين</small>
                    <small class="fa fa-users text-primary" v-if="item.type == 'students'"> للطلاب</small>
                    &nbsp;
                    {{ item.title }}
                  </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-12 c g" v-if="current._id">
        <div class="row">
          <div class="col-12 col-lg-3">
            <button class="btn btn-block btn-primary" @click="save()" style="border-radius: 0px">
              <i class="fa fa-save"></i>
              حفظ التعديلات
            </button>
          </div>
          <div class="col-12 col-lg-3">
            <button class="btn btn-block btn-warning" @click="custom_classname = '--'; custom_classroom = '--';" v-b-modal.print style="border-radius: 0px">
              <i class="fa fa-print"></i>
              طباعة
            </button>
            <b-modal id="print" title="طباعة" hide-footer size="sm">
              <template v-if="current.type == 'teachers'">
                <button class="btn btn-primary btn-block" @click="print('all')">
                  <i class="fa fa-users"></i>
                  طباعة الآن
                </button>
              </template>
              <template v-if="current.type == 'students'">
                <button class="btn btn-primary btn-block" @click="print('all')">
                  <i class="fa fa-users"></i>
                  طباعة كل طلاب المدرسة
                </button>
                <br>
                <select class="form-control border shadow" style="border: 2px solid darkred !important" v-model="custom_classname" @change="print('classname');">
                  <option value="--">طباعة كشف صف معين</option>
                  <option v-for="c in classnames" :key="c">{{ c }}</option>
                </select>
                <br>
                <select class="form-control border shadow" style="border: 2px solid darkred !important" v-model="custom_classroom" @change="print('classroom');">
                  <option value="--">طباعة فصل صف معين</option>
                  <option v-for="c in classrooms" :key="c" :value="c">{{ c.replace("->", " - ") }}</option>
                </select>
              </template>
            </b-modal>
          </div>
        </div>
        <div class="card">
            <div class="card-header">
              <h5>
                <i class="fa fa-list-alt"></i>
                التروسية <span class="text-muted">(الجزء العلوي)</span>
              </h5>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-12 col-lg-4">
                    <span>اليمين</span>
                    <ckeditor :editor="editor" dir="rtl" v-model="header.right" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span>الوسط</span>
                    <ckeditor :editor="editor" dir="rtl" v-model="header.center" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span>اليسار</span>
                    <ckeditor :editor="editor" dir="rtl" v-model="header.left" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="col-12 g">
                    <ckeditor :editor="editor" dir="rtl" v-model="header.bottom" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="col-12">
                    المتغيرات:
                    <template v-if="current.type == 'students'">
                      <span class="btn btn-outline-secondary btn-sm" style="border-radius: 0px">
                        الصف
                      </span>
                      <span class="btn-outline-success btn-sm" style="border-radius: 0px; padding: 1px">
                        {classname}
                      </span>
                      &nbsp;
                      <span class="btn btn-outline-secondary btn-sm" style="border-radius: 0px">
                        الفصل
                      </span>
                      <span class="btn-outline-success btn-sm" style="border-radius: 0px; padding: 1px">
                        {classroom}
                      </span>
                      &nbsp;
                    </template>
                    <span class="btn btn-outline-secondary btn-sm" style="border-radius: 0px">
                      تاريخ اليوم
                    </span>
                    <span class="btn-outline-success btn-sm" style="border-radius: 0px; padding: 1px">
                      {date}
                    </span>
                  </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
              <h5>
                <i class="fa fa-table"></i>
                المحتوى
              </h5>
            </div>
            <div class="card-body">
                <div class="col-12 table-responsive" style="min-height: 100px">
                  <b-dropdown class="hidemonprint" dropbottom no-caret
                      id="dropdown-1" style="border-radius: 0px"
                      :html="`<i class='fa fa-plus'></i> إضافة حقل`"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="success"
                  >
                      <b-dropdown-item @click="createInput.type = 'auto'; createInput.index = (Object.keys(current.inputs).length)+1" v-b-modal.create-input>
                        <i class="fa fa-star"></i>
                        حقل تلقائي
                      </b-dropdown-item>
                      <b-dropdown-item @click="createInput.type = 'manual'; createInput.index = (Object.keys(current.inputs).length)+1" v-b-modal.create-input>
                        <i class="fa fa-edit"></i>
                        حقل فارغ
                      </b-dropdown-item>
                  </b-dropdown>
                  <b-modal id="create-input" title="انشاء حقل جديد" hide-footer size="sm">
                    <div class="form-group">
                      <h5 for="">اسم الحقل</h5>
                      <input type="text"
                        class="form-control" v-model="createInput.title" placeholder="اكتب هنا...">
                    </div>
                    <div class="form-group" v-if="createInput.type == 'auto'">
                      <h5 for="">يمثل الحقل</h5>
                      <select class="form-control" v-model="createInput.input">
                        <option value="--">-- اختر --</option>
                        <option v-for="i in inputs" :value="i" :key="i">{{ inputName(i) }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <h5 for="">الترتيب</h5>
                      <input type="number" v-model="createInput.index"
                        class="form-control">
                    </div>
                    <div class="col-12 text-center g">
                      <button class="btn btn-success" @click="createInputNow()">
                        <i class="fa fa-check"></i>
                        إضافة الحقل
                      </button>
                    </div>
                  </b-modal>
                  <br><br>
                  <table class="table table-bordered table-sm table-hover">
                    <thead>
                      <th>
                        الاسم
                      </th>
                      <th>
                        النوع
                      </th>
                      <th>
                        خيارات
                      </th>
                    </thead>
                    <tbody>
                      <tr v-for="input in current.inputs" :key="input.code">
                        <td>
                          {{ input.title }}
                        </td>
                        <td>
                          {{ input.type == 'auto' ? 'تلقائي' : 'فارغ' }}
                          <span v-if="input.type == 'auto'">
                            <br>
                            <small class="text-muted">
                              {{ inputName(input.input) }}
                            </small>
                          </span>
                        </td>
                        <td>
                          <button class="btn btn-outline-danger btn-sm" style="border-radius: 0px" @click="deleteInput(input.code)">
                            <i class="fa fa-trash"></i>
                            ازالة
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
              <h5>
                <i class="fa fa-list-ul"></i>
                زيل الصفحة
              </h5>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-12 g">
                    <ckeditor :editor="editor" dir="rtl" v-model="footer.top" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span>اليمين</span>
                    <ckeditor :editor="editor" dir="rtl" v-model="footer.right" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span>الوسط</span>
                    <ckeditor :editor="editor" dir="rtl" v-model="footer.center" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="col-12 col-lg-4">
                    <span>اليسار</span>
                    <ckeditor :editor="editor" dir="rtl" v-model="footer.left" :config="editorConfig"></ckeditor>
                  </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BModal, VBModal, BDropdown, BDropdownItem, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Templates from '../pages/templates.vue';
const XLSX = require('xlsx');
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
        BFormCheckbox,
        Templates
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
      return {
        user: JSON.parse(localStorage.getItem('user')),
        current: {},
        createInput: {
          input: '--',
          type: ""
        },
        allowedInputs: [
          "name",
          "phone",
          "number",
          "phones",
          "classname",
          "classroom",
          "rate",
          "username",
          "password"
        ],
        header: {
          left: "",
          right: "",
          center: ""
        },
        footer: {
          left: "",
          right: "",
          center: ""
        },
        inputs: [],
        inertval: null,
        list: [],
        students: [],
        classrooms: [],
        classnames: [],
        custom_classname: '--',
        custom_classroom: '--',
        create: {type: 'students'},
        editor: ClassicEditor,
        editorData: '<p>Content of the editor.</p>',
        editorConfig: {
          language: {
            ui: 'ar',
            content: 'ar',
        }
        },
      }
    },
    beforeDestroy(){
      if(this.interval){
        clearInterval(this.inertval)
      }
    },
    created(){
      this.gett()
    },
    methods: {
      gett2(){
        var g = this;
        if(g.current.type == 'students'){
          $.post(api + '/user/students/list', {
              jwt: this.user.jwt,
              page: 1,
              perpage: 10000
          }).then(function(r){
              g.loading = false
              if(r.status != 100){
                  alert(r.response)
              }else{
                  g.students = r.response;
                  g.students.forEach(function(a){
                    if(!g.classnames.includes(a.classname)){
                      g.classnames.push(a.classname)
                    }
                    if(!g.classrooms.includes(a.classname + '->' + a.classroom)){
                      g.classrooms.push(a.classname + '->' + a.classroom)
                    }
                    for (const [key, value] of Object.entries(a)) {
                      if(!g.inputs.includes("#")){
                        g.inputs.push("#")
                      }
                      if(!g.inputs.includes(key) && g.allowedInputs.includes(key)){
                        g.inputs.push(key)
                      }
                    }
                  })
              }
          }).fail(function(){
              g.loading = false;
          })
        }else{
          $.post(api + '/user/teachers/list', {
              jwt: this.user.jwt,
          }).then(function(r){
              g.loading = false
              if(r.status != 100){
                  alert(r.response)
              }else{
                  g.teachers = r.response;
                  g.teachers.forEach(function(a){
                    for (const [key, value] of Object.entries(a)) {
                      if(!g.inputs.includes("#")){
                        g.inputs.push("#")
                      }
                      if(!g.inputs.includes(key) && g.allowedInputs.includes(key)){
                        g.inputs.push(key)
                      }
                    }
                  })
              }
          }).fail(function(){
              g.loading = false;
          })
        }
      },
      deleteInput(code){
        if(confirm('متأكد من ازالة الحقل؟')){
          var g = this;
          var arr = [];
          g.current.inputs.forEach(function(a){
            if(a.code != code){
              arr.push(a)
            }
          })
          g.current.inputs = arr;
          g.current = JSON.parse(JSON.stringify(g.current))
        }
      },
      print(type){
        var g = this;
        var mywindow = window.open();
        function compare( a, b ) {
          if ( a.index < b.index ){
            return -1;
          }
          if ( a.index > b.index ){
            return 1;
          }
          return 0;
        }
        g.current.inputs = g.current.inputs.sort( compare );
        var fields = "", body = "";
        g.current.inputs.forEach(function(a){
          fields = fields + `
          <th>${a.title}</th>
          `
        })
        if(g.current.type == 'students'){
          if(type == 'all'){
            var i = 0;
            g.students.forEach(function(student){
              i = i+1
              body = body + `<tr>`;
              g.current.inputs.forEach(function(a){
                if(a.type == 'manual'){
                  body = body + `<td></td>`
                }else{
                  body = body + `<td>${a.input == '#' ? i : student[a.input]}</td>`
                }
              })
              body = body + `</tr>`;
            })
          }
          if(type == 'classname'){
            var i = 0;
            g.students.forEach(function(student){
              if(student.classname == g.custom_classname){
              i = i+1
                body = body + `<tr>`;
                g.current.inputs.forEach(function(a){
                  if(a.type == 'manual'){
                    body = body + `<td></td>`
                  }else{
                    body = body + `<td>${a.input == '#' ? i : student[a.input]}</td>`
                  }
                })
                body = body + `</tr>`;
              }
            })
          }
          if(type == 'classroom'){
            var i = 0;
            g.students.forEach(function(student){
              if(student.classname == g.custom_classroom.split("->")[0] && student.classroom == g.custom_classroom.split("->")[1]){
                
              i = i+1
              body = body + `<tr>`;
                g.current.inputs.forEach(function(a){
                  if(a.type == 'manual'){
                    body = body + `<td></td>`
                  }else{
                    body = body + `<td>${a.input == '#' ? i : student[a.input]}</td>`
                  }
                })
                body = body + `</tr>`;
              }
            })
          }
        }else{
          g.teachers.forEach(function(student, i){
            body = body + `<tr>`;
            g.current.inputs.forEach(function(a){
              if(a.type == 'manual'){
                body = body + `<td></td>`
              }else{
                body = body + `<td>${a.input == '#' ? i+1 : student[a.input]}</td>`
              }
            })
            body = body + `</tr>`;
          })
        }
        var html = `
        <table class='table table-sm'>
          <thead>
            ${fields}
          </thead>
          <tbody>${body}</tbody>
        </table>
        `;
        var print_html = `
        <!DOCTYPE html>
        <html dir="rtl">
          <head>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
            <style>
              table, td, th {
                border: 1px solid;
                padding: 0px !important
              }
              td, th{
                padding-right: 5px !important
              }

              table {
                width: 100%;
                border-collapse: collapse;
              }
              @page {
                  margin: 0;
              }
              img{
              max-width: 200px
              }
            </style>
          </head>
          <body>
            <div class='container-fluid'>
              <div class='row'>
                <div class='col-4 text-center'>
                  ${convertToHijri(g.header.right
                  .replace("{date}", new Date().toISOString().split("T")[0])
                  .replace("{classname}", g.custom_classname)
                  .replace("{classroom}", g?.custom_classroom?.split('->')[1])
                  )}
                </div>
                <div class='col-4 text-center'>
                  ${convertToHijri(g.header.center
                  .replace("{date}", new Date().toISOString().split("T")[0])
                  .replace("{classname}", g.custom_classname)
                  .replace("{classroom}", g?.custom_classroom?.split('->')[1])
                  )}
                </div>
                <div class='col-4'>
                  ${convertToHijri(g.header.left
                  .replace("{date}", new Date().toISOString().split("T")[0])
                  .replace("{classname}", g.custom_classname)
                  .replace("{classroom}", g?.custom_classroom?.split('->')[1])
                  )}
                </div>
                <div class='col-12'>
                  ${convertToHijri(g.header.bottom
                  .replace("{date}", new Date().toISOString().split("T")[0])
                  .replace("{classname}", g.custom_classname)
                  .replace("{classroom}", g?.custom_classroom?.split('->')[1])
                  )}
                </div>
                <div class='col-12'>
                  ${html}
                </div>
                <div class='col-12'>
                  ${convertToHijri(g.footer.top
                  .replace("{date}", new Date().toISOString().split("T")[0])
                  .replace("{classname}", g.custom_classname)
                  .replace("{classroom}", g?.custom_classroom?.split('->')[1])
                  )}
                </div>
                <div class='col-4'>
                  ${convertToHijri(g.footer.right
                  .replace("{date}", new Date().toISOString().split("T")[0])
                  .replace("{classname}", g.custom_classname)
                  .replace("{classroom}", g?.custom_classroom?.split('->')[1])
                  )}
                </div>
                <div class='col-4'>
                  ${convertToHijri(g.footer.center
                  .replace("{date}", new Date().toISOString().split("T")[0])
                  .replace("{classname}", g.custom_classname)
                  .replace("{classroom}", g?.custom_classroom?.split('->')[1])
                  )}
                </div>
                <div class='col-4'>
                  ${convertToHijri(g.footer.left
                  .replace("{date}", new Date().toISOString().split("T")[0])
                  .replace("{classname}", g.custom_classname)
                  .replace("{classroom}", g?.custom_classroom?.split('->')[1])
                  )}
                </div>
              </div>
            </div>
          </body>
        </html>
        `;
        mywindow.document.write(print_html);
        mywindow.document.close();
        setTimeout(() => {
          mywindow.print();
        }, 1000);

      },
      createInputNow(){
        var g = this;
        g.current.inputs.push({
          title: g.createInput.title,
          type: g.createInput.type,
          input: g.createInput.input,
          index: g.createInput.index,
          code: Math.random()
        })
        g.current = JSON.parse(JSON.stringify(g.current))
        $("#create-input___BV_modal_header_ > button").click()
      },
      save(alertt=false){
        var g = this;
        var v = JSON.parse(JSON.stringify(g.current));
        v.header = g.header;
        v.footer = g.footer;
        $.post(api + '/user/mentors/designer/save', {
            jwt: g.user.jwt,
            obj: JSON.stringify(v)
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                if(alertt==false){
                  alert("تم الحفظ بنجاح", 100)
                }
            }
        }).fail(function(){
            g.loading = false;
            alert("حدث خطأ في الاتصال")
        })
      },
      gett(){
        var g = this;
        $.post(api + '/user/mentors/designer/list', {
            jwt: g.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.list = r.response
            }
        }).fail(function(){
            g.loading = false;
            alert("حدث خطأ في الاتصال")
        })
      },
      createNow(){
        var g = this;
        $.post(api + '/user/mentors/designer/create', {
            jwt: g.user.jwt,
            title: g.create.title ?? 'بدون اسم',
            type: g.create.type ?? 'students'
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.gett()
                $("#create___BV_modal_header_ > button").click()
                alert("تم الانشاء بنجاح", 100)
            }
        }).fail(function(){
            g.loading = false;
            alert("حدث خطأ في الاتصال")
        })
      },
      inputName(i){
        var ar = {
          "name": "الاسم",
          "phone": "الجوال",
          "phones": "الجوال",
          "number": "رقم الهوية",
          "rate": "التقييم",
          "classname": "الصف",
          "classroom": "الفصل",
          "#": "رقم تسلسلي",
          "username": "اسم المستخدم",
          "password": "كلمة المرور",
        };
        return ar[i]
      }
    }
}
</script>

<style>

</style>